<template>
  <div class="pdf-container">
    <a class="pdf" href="/pdf/CSR Newsletter.pdf" aria-label="CSR Newsletter link"></a>
  </div>
</template>

<script>
export default {
  name: 'default',
  metaInfo: {
    title: 'CSR-2021-Q2',
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/CSR-2021-Q2/' }],
  },
  data() {
    return {
      pages: '4',
    };
  },
  mounted() {
    this.redirectToPDF();
  },
  computed: {},
  watch: {},
  methods: {
    redirectToPDF() {
      const c = document.createEvent('MouseEvents');
      c.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      const button = document.querySelector('.pdf');
      button.dispatchEvent(c);
    },
  },
};
</script>

<style lang="scss">
.pdf-container {
  max-width: 1200px;
  margin: 0 auto;
  height: 100vh;
  overflow: scroll;

  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
